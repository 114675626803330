<template>
  <div></div>
</template>


<script>
export default {
  name: "CountryMap",
  data: () => ({
    title: "CountryMap"
  }),
  components: {}
};
</script>